<template>
  <div>
    <!-- 头部按钮 -->
    <div class="top">
      <div class="left">
        <el-button @click="backClick" class="return">返回</el-button>
        <div>出货记录</div>
      </div>
      <!-- <el-button v-print="printObj" class="export">打印</el-button> -->
      <el-button   @click="printContext" class="export">打印</el-button>
      <!-- <el-button @click="handleDownload" class="export">导出</el-button> -->
      <!-- <el-button class="export">导出</el-button> -->
    </div>
    <!-- 表单 -->
    <div class="from" ref="print" id="printTest">
      
      <div class="from-title">出货单</div>
      <div class="from-top">
        <div class="from-top-left">出库商城：{{allData.warehouse}}</div>
        <div class="from-top-right">出库日期：{{allData.create_time | timeFilter}}</div>
      </div>
      <el-table 
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-background="#fff"
        :data="tableData"
        ref=“table”
        show-summary
        :summary-method="getSummaries"
        border
        stripe
        style="width: 100%">
        <el-table-column width="150" label="条形码" prop="bar_code" align="center"> </el-table-column>
        <!-- <el-table-column label="商品图" align="center" class="no-print"> 
            <template scope="scope">
                <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.goods_url"
                ></el-image>
            </template>
        </el-table-column> -->
        <!-- <el-table-column width="110" label="二维码" align="center"> 
            <template scope="scope">
                <vue-qr v-if="scope.row.qr_code"  :text="scope.row.qr_code" :size="100"></vue-qr>
                <div v-else>暂无数据</div>
            </template>
        </el-table-column> -->
        <el-table-column label="型号" width="140" prop="goods_type" align="center"> </el-table-column>
        <el-table-column label="品牌" width="80" prop="goods_brand" align="center"> </el-table-column>
        <el-table-column label="规格" width="80" prop="goods_norms" align="center"> </el-table-column>
        <el-table-column label="单价" width="100" prop="price" align="center"> </el-table-column>
        <el-table-column label="折扣比例" width="100" prop="zk" align="center"> </el-table-column>
        <el-table-column label="折扣金额" width="100" prop="zk_price" align="center"> </el-table-column>
        <el-table-column label="奖励" width="80" prop="reward" align="center"> </el-table-column>
        <el-table-column label="配件" width="80" prop="goods_other" align="center"> </el-table-column>
        <el-table-column label="备注" width="110" prop="remark" align="center"> </el-table-column>
      </el-table>
      <div class="bottom-all">
        <div>开单编号二维码</div>
        <vue-qr v-if="allData.qr" :margin="10" :text="allData.qr" :size="200"></vue-qr>
      </div>
      
    </div>    
  </div>
</template>

<script>
import { info} from '@/api/index.js'
import * as Printjs from "print-js";
export default {
  data() {
    return {
      tableData: [],
      loading:true,
      allData:{},
      printObj: {
        id: "printTest",//打印区域 Dom ID
        popTitle: '出库单',
        extraCss: ' ',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #printTest { height: auto !important;width:100% !important;font-size: 36px;. }.from-top{font-size: 36px;} <style>'  ,//  可以传进去  style tag 标签；注意要逗号分隔   解决特定区域不显示问题；
      }
    };
  },
  created(){
    let data = JSON.parse(this.$route.query.key)
    if(data){
      this.allData = data
      this.getListApi(data.id)
    }else{
      this.backClick()
      this.$message.error('非法进入');
    }
  },
  // updated () {
  //   this.$nextTick(() => {
  //     this.$refs['table'].doLayout();
  //   }) 
  // },
  methods:{
    

    // 请求账单接口
    getListApi(data){
      info({shipment_id:data}).then(res=>{
        this.tableData = res.data.data
        this.loading = false
      })
    },
    // 导出数据
    handleDownload() {
        import('@/vendor/Export2Excel').then(excel => {
          const multiHeader =
          [
            ['出货单','', '', '', '', '','', '', '', '', ''],
            ['出库商城:',this.allData.warehouse, '', '', '', '','', '出库日期:', this.allData.warehouse],
          //   ['', '姓名', '地址', '', '', ''] //第二行
          ] // 前两行的表头数据，二维数组，不够的用空白补全
          const header = ['条形码', '二维码', '折扣比例', '折扣金额', '型号', '品牌', '规格', '类别', '颜色', '单价', '奖励'] // 最后一行的表头数据
          const filterVal = ['bar_code', 'bar_code', 'bar_code', 'bar_code', 'bar_code', 'bar_code','bar_code','bar_code','bar_code','bar_code','bar_code']
          const list = this.tableData
          const data = this.formatJson(filterVal, list)
          const merges = ['A1:K1','A3:A4','B3:C3','D3:E3','F3:G3','H3:I3','J3:K3'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
          excel.export_json_to_excel({
            multiHeader,
            header,
            merges,
            data,
            filename:'对账清单',
          })
        })
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计' + this.tableData.length + '个';
          return;
        }
        if(index == 5 || index == 9){
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          let price = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          price = parseFloat(price.toFixed(2))
          sums[index] = price + ' 元';
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    printClick(){
      let str = "<style> color:red</style>"
      Printjs({
          printable: "printTest", //要打印内容的id
          type: "html", //可以打印html,img详细的可以在官方文档https://printjs.crabbly.com/中查询
          // 继承原来的所有样式
          targetStyles: ['width:100%'],
          documentTitle:'出库单',
          font_size :'22px',
          style:str
      });
    },
    printContext () {
        this.$print(this.$refs.print)
    },
    backClick(){
      this.$router.go(-1)
    },
  }
};
</script>


<style >
.el-table--border:after,.el-table--group:after,.el-table:before {background-color:  #000;}
.el-table--border,.el-table--group {  border-color: #000;}
.el-table td,.el-table th.is-leaf { border: 2px solid  #000;}
.el-table--border th,.el-table--border th.gutter:last-of-type {border: 2px solid  #000;}
.el-table--border td,.el-table--border th {border: 2px solid  #000;}
.el-table__footer-wrapper{border:  #000 solid 2px;}
</style>

<style scoped>
.top {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 100px;
}
.top .export {
  width: 105px;
  height: 50px;
  background: linear-gradient(90deg, #ff861b, #ffb527);
  box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
  border-radius: 6px;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border:none
}
.top .left {
  display: flex;
  align-items: center;
}
.top .left .return {
  width: 107px;
  height: 44px;
  background: #e6e6e6;
  border-radius: 22px;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border:none
}
.top .left .txt {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-left: 31px;
}
/* .from {
  box-sizing: border-box;
  padding: 0 100px;
} */
.from .title {
  font-size: 51px;
  font-weight: 500;
  color: #333333;
  line-height: 90px;
  text-align: center;
}
.from .from-top {
  margin-bottom: 80px;
  font-size: 30px;
  width: 100%;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.from .from-bom {
  height: 100px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
#printTest{
  width: 100%;
}
.from-title{
  width: 100%;
  text-align: center;
  font-size: 34px;
}
#printTest{
  width: 100%;
  /* margin:  auto; */
}
.bottom-all{
  margin-top: 20px;
}
</style>

<style media="print">
.from-title{
  font-size: 40px;
}
</style>