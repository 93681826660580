import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'
/**
 * 获取路由
 */
export function login(data={}) {
  return service.request({
    url: '/admin/login',
    method: 'get',
    data
  })
}

/**
 * 获取下级城市
 */
export function get_area_list(data={}) {
  return service.request({
    url: '/api/user/area',
    method: 'get',
    params:data
  })
}


/**
 * 上传图片
 */
export function upload_image(data={}) {
  let formData = new FormData()
  console.log(data)
  formData.append('image', data.image)
  return service.request({
    url: '/admin/purchase/upload',
    method: 'post',
    type: 'upload',
    data: formData
  })
}


/**
 * 获取对账清单
 */
export function reconciliation(data={}) {
  return service.request({
    url: '/admin/warehouse/reconciliation',
    method: 'get',
    params:data
  })
}

/**
 * 出货单详情
 */
export function info(data={}) {
  return service.request({
    url: '/admin/shipment/info',
    method: 'post',
    data
  })
}



